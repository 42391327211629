import React from "react";
import PropTypes from "prop-types";
import PostLink from "../components/BlogContent/post-link";

// Utilities
import kebabCase from "lodash/kebabCase";

// Components
import { Link, graphql } from "gatsby";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";

const ProjectPage = ({ data }) => {
  const { t } = useTranslation();
  const edges = data.postData.edges;
  const group = data.tagData.group;
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Projekte")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Aktuell")}
      />
      <div className="widget-area">
        <div className="container widget widget_tag_cloud">
          <h3 className="widget-title">{t("Tags")}</h3>

          <div className="tagcloud">
            {group.map((tag) => (
              <Link
                key={Math.random()}
                to={`/projects/${kebabCase(tag.fieldValue)}/`}
              >
                <span className="post-count">
                  {" "}
                  {tag.fieldValue} ({tag.totalCount}){" "}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="blog-area ptb-100">
        <div className="container">
          <div className="row">
            {edges.map((edge) => (
              <PostLink key={edge.node.id} post={edge.node} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

ProjectPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
};

export default ProjectPage;

export const pageQuery = graphql`
  query IndexPageQuery($language: String!) {
    tagData: allMarkdownRemark(
      limit: 2000
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/markdown-pages/)/" }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    postData: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/markdown-pages/)/" }
      }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(locale: $language, formatString: "MMMM DD, YYYY")
            slug
            title
            images {
              mainImage {
                childImageSharp {
                  gatsbyImageData(width: 800, layout: CONSTRAINED)
                }
              }
              galleryImages {
                childImageSharp {
                  gatsbyImageData(width: 800, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
