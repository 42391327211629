import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const PostLink = ({ post }) => {
  let featuredImgFluid =
    post.frontmatter.images.mainImage.childImageSharp.gatsbyImageData;
  return (
    <div className="col-lg-4 col-md-6">
      <div className="single-blog-post bg-81a0a6">
        <div className="post-image">
          <Link to={post.frontmatter.slug}>
            <GatsbyImage alt="Project" image={featuredImgFluid} />
          </Link>
        </div>
        <div>
          <div className="post-content">
            <ul className="post-meta d-flex justify-content-between align-items-center">
              <li key={Math.random()}>
                <div className="post-author d-flex align-items-center">
                  <span>Blockstrom</span>
                </div>
              </li>
              <li key={Math.random()}>
                <i className="flaticon-calendar"></i> {post.frontmatter.date}
              </li>
            </ul>
            <h3>
              <Link to={post.frontmatter.slug}>{post.frontmatter.title}</Link>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostLink;
